<script setup lang="ts">
import { ref, computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { getFutureAndLiveMatches } from '@/gql/requests'
import Button from '@/components/UI/Button.vue'
import FutureMatch from '@/components/Match/Future.vue'
import MatchesHeader from '@/components/Match/MatchesHeader.vue'
import type { FilterMatches } from '@/types/matches'

const activeHeader = ref(true)

const { result } = useQuery(getFutureAndLiveMatches)

const statusFilter = ref<FilterMatches>('all')
const matchesFiltered = computed(() => {
   if (statusFilter.value === 'all') {
      return (
         result.value?.matches
            .filter((match) => match.status === 'UPCOMING')
            .sort((a, b) => (Array.isArray(b.chance) ? 1 : 0) - (Array.isArray(a.chance) ? 1 : 0))
            .sort((a, b) => Number(b.isPromo) - Number(a.isPromo)) ?? []
      )
   } else {
      return (
         result.value?.matches
            .filter(
               (match) => match.status === 'UPCOMING' && (statusFilter.value === 'recommended' ? match.recommended : !match.recommended)
            )
            .sort((a, b) => (Array.isArray(b.chance) ? 1 : 0) - (Array.isArray(a.chance) ? 1 : 0))
            .sort((a, b) => Number(b.isPromo) - Number(a.isPromo)) ?? []
      )
   }
})

const matches = computed(() => {
   if (matchesFiltered.value.length > matchesShown.value) {
      return matchesFiltered.value.slice(0, matchesShown.value)
   } else {
      return matchesFiltered.value
   }
})

const matchesShown = ref(6)
const showButtonMore = computed(() => matchesFiltered.value.length > matchesShown.value)
const showMore = () => {
   matchesShown.value += 6
}
</script>

<template>
   <section class="grid gap-y-20px <laptop:px-16px">
      <MatchesHeader v-model="activeHeader" v-model:filter="statusFilter" :count="matchesFiltered.length">{{
         $t('dashboard.upcomingMatches.title')
      }}</MatchesHeader>
      <div v-if="activeHeader" class="grid grid-cols-3 <tablethd:grid-cols-2 <tablet:grid-cols-1 gap-20px <fullhd:gap-16px">
         <FutureMatch v-for="match in matches" :key="match.id" :match="match" />
      </div>
      <div v-if="activeHeader && showButtonMore" class="flex justify-center">
         <Button class="w-230px h-45px m-semibold" :gradient-start="false" @click="showMore">{{
            $t('dashboard.upcomingMatches.button')
         }}</Button>
      </div>
   </section>
</template>

<style scoped></style>

import { createRouter, createWebHistory, RouterView } from 'vue-router'
import { routeMiddleware } from '@/i18n'
import HomePage from '@/pages/Home/HomePage.vue'
import { lastRouteInfo } from '@/composables/index'

const router = createRouter({
   scrollBehavior() {
      document.querySelector('#globalScroll')?.scroll(0, 0)
      document.querySelector('#app')?.scroll(0, 0)
   },
   history: createWebHistory(import.meta.env.BASE_URL),
   routes: [
      {
         path: '/:locale?',
         component: RouterView,
         children: [
            {
               path: '',
               name: 'home',
               component: HomePage,
               meta: {
                  layout: 'Default',
                  title: 'DataLouna: прогнозы на матчи CS от искусственного интеллекта Даталуна',
                  titleEN: 'DataLouna: CS predictions powered by AI',
                  description:
                     'Искусственный интеллект DataLouna и 6 уникальных инструментов помогут принять взвешенное решение о ставке на CS',
                  descriptionEN: 'Unlock the Secrets of CS Betting with DataLouna AI and our 6 Unique Tools'
               }
            },
            {
               path: 'user',
               name: 'user',
               component: () => import('@/pages/User/UserPage.vue'),
               meta: {
                  layout: 'Default',
                  title: 'Управление аккаунтом DataLouna',
                  titleEN: 'Manage your Account',
                  description: 'Управление аккаунтом DataLouna',
                  descriptionEN: 'Manage your Account'
               }
            },
            {
               path: 'education',
               name: 'education',
               component: () => import('@/pages/Education/EducationPage.vue'),
               meta: {
                  layout: 'Default',
                  title: 'База знаний: главная страница',
                  titleEN: 'Knowledge Base: Main Page',
                  description: 'Главная страница Базы знаний на DataLouna',
                  descriptionEN: 'DataLouna’s Knowledge Base: Main Page'
               }
            },
            {
               path: 'education/datalouna',
               name: 'education-datalouna',
               component: () => import('@/pages/Education/Category/DatalounaCategory.vue'),
               meta: {
                  layout: 'Default',
                  title: 'База знаний DataLouna: как работает ИИ и как пользоваться сервисом',
                  titleEN: 'DataLouna Knowledge Base: How the AI works and how to use the service',
                  description: 'Как работает DataLouna и как пользоваться сервисом',
                  descriptionEN: 'How DataLouna works and how to use the service'
               }
            },
            {
               path: 'education/bets',
               name: 'education-bets',
               component: () => import('@/pages/Education/Category/BetsCategory.vue'),
               meta: {
                  layout: 'Default',
                  title: 'Как ставить на КС и киберспорт: гайды по ставкам на Counter-Strike',
                  titleEN: 'How to bet on CS and esports: guides on betting on Counter-Strike',
                  description: 'Как научиться ставить на КС: обучающие материалы от DataLouna',
                  descriptionEN: 'How to learn to bet on CS: training materials by DataLouna'
               }
            },

            {
               path: 'education/cs',
               name: 'education-cs',
               component: () => import('@/pages/Education/Category/CSCategory.vue'),
               meta: {
                  layout: 'Default',
                  title: 'Гайды Counter-Strike: как понимать КС на уровне про',
                  titleEN: 'CS Guides: Dive Deep into Pro Level Knowledge',
                  description: 'Как понимать КС: обучающие материалы от DataLouna',
                  descriptionEN: 'Made CS Clear: Training Materials from DataLouna'
               }
            },
            {
               path: 'education/bets/:slug',
               name: 'education-bets-article',
               component: () => import('@/pages/Education/Article.vue'),
               meta: {
                  layout: 'Default'
               }
            },
            {
               path: 'education/cs/:slug',
               name: 'education-cs-article',
               component: () => import('@/pages/Education/Article.vue'),
               meta: {
                  layout: 'Default'
               }
            },
            {
               path: 'education/datalouna/:slug',
               name: 'education-datalouna-article',
               component: () => import('@/pages/Education/ArticleDatalouna.vue'),
               meta: {
                  layout: 'Default'
               }
            },
            {
               path: 'guides',
               name: 'guides',
               component: () => import('@/pages/Education/Category/Guides.vue'),
               meta: {
                  layout: 'Default',
                  title: 'Гайды Counter-Strike 2 от DataLouna: консольные команды и обучающие материалы',
                  titleEN: 'Counter-Strike 2 Guides by DataLouna: Console Commands and Educational Materials',
                  description: 'Обучающие материалы по игре Counter-Strike 2, созданные командой DataLouna',
                  descriptionEN: 'Educational materials on Counter-Strike 2, created by the DataLouna team'
               }
            },
            {
               path: 'guides/:slug',
               name: 'guides-article',
               component: () => import('@/pages/Education/Guide.vue'),
               meta: {
                  layout: 'Default'
               }
            },
            {
               path: 'about',
               name: 'about',
               component: () => import('@/pages/About/AboutPage.vue'),
               meta: {
                  layout: 'Default',
                  title: 'Отзывы DataLouna: команда, история создания и миссия Даталуна',
                  titleEN: 'DataLouna Reviews: team, creation history, and mission of DataLouna',
                  description: 'Отзывы о DataLouna от реальных пользователей, Информация о команде DataLouna и миссии проекта',
                  descriptionEN: "Reviews about DataLouna from real users, Information about the DataLouna team and the project's mission"
               }
            },
            {
               path: 'history',
               name: 'history',
               redirect: () => {
                  return { name: 'history-matches' }
               }
            },

            {
               path: 'history/matches',
               name: 'history-matches',
               component: () => import('@/pages/History/HistoryMatchesPage.vue'),
               meta: {
                  layout: 'Default',
                  title: 'История прогнозов DataLouna на матчи',
                  titleEN: "DataLouna's Match Prediction History",
                  description: 'Полная статистика прогнозов ИИ DataLouna на матчи Counter-Strike',
                  descriptionEN: "All the stats on DataLouna AI's Counter-Strike match predictions"
               }
            },
            {
               path: 'history/events',
               name: 'history-events',
               component: () => import('@/pages/History/HistoryEventsPage.vue'),
               meta: {
                  layout: 'Default',
                  title: 'История прогнозов DataLouna на турнирах',
                  titleEN: "DataLouna's Events Prediction History",
                  description: 'Полная статистика прогнозов ИИ DataLouna на турнирах Counter-Strike',
                  descriptionEN: "All the stats on DataLouna AI's Counter-Strike event predictions"
               }
            },
            {
               path: 'history/events/:slug',
               name: 'event',
               component: () => import('@/pages/History/EventPage.vue'),
               meta: {
                  layout: 'Default'
               }
            },
            {
               path: 'matches',
               name: 'matches',
               component: () => import('@/pages/Matches/MatchesPage.vue'),
               meta: {
                  layout: 'Default',
                  title: 'Актуальные прогнозы на Counter-Strike от DataLouna - прогнозы и аналитика на матчи КС',
                  titleEN: 'Up-to-date Counter-Strike predictions by DataLouna - forecasts and analysis for CS matches',
                  description: 'Прогнозы от искусственного интеллекта на матчи Counter-Strike и предматчевые комментарии экспертов',
                  descriptionEN: 'Predictions powered by AI for Counter-Strike matches and pre-match expert insights'
               }
            },
            {
               path: 'matches/:id',
               name: 'match',
               component: () => import('@/pages/Match/MatchPage.vue'),
               meta: {
                  layout: 'Default'
               }
            },
            {
               path: 'subscription',
               name: 'subscription',
               component: () => import('@/pages/Subscription/SubscriptionPage.vue'),
               meta: {
                  layout: 'Default',
                  title: 'Тарифы DataLouna: купить подписку',
                  titleEN: "DataLouna's Pricing: Subscribe",
                  description: 'Официальная страница с тарифными планами DataLouna',
                  descriptionEN: "DataLouna's Pricing - Official Page"
               }
            },
            {
               path: 'signin',
               name: 'signin',
               component: () => import('@/pages/Auth/SignIn.vue'),
               meta: {
                  layout: 'Auth'
               }
            },
            {
               path: 'signin/recovery',
               name: 'recovery',
               component: () => import('@/pages/Auth/RecoveryPassword.vue'),
               meta: {
                  layout: 'Auth'
               }
            },
            {
               path: 'signup',
               name: 'signout',
               component: () => import('@/pages/Auth/SignUp.vue'),
               meta: {
                  layout: 'Auth'
               }
            },
            {
               path: 'error',
               name: 'error',
               component: () => import('@/pages/ErrorPage.vue'),
               meta: {
                  layout: 'Errors'
               }
            },
            {
               path: ':pathMatch(.*)*',
               name: '404',
               component: () => import('@/pages/404Page.vue'),
               meta: {
                  layout: 'Errors'
               }
            },
            {
               path: 'info',
               name: 'info',
               component: () => import('@/pages/Info.vue'),
               meta: {
                  layout: 'Auth'
               }
            }
         ]
      }
   ]
})

router.beforeEach(async (to, from) => {
   const { layout, title, titleEN, description, descriptionEN } = to.meta

   if (from.name && from.meta.layout !== 'Auth') {
      lastRouteInfo.value = {
         name: from.name as string,
         params: from.params
      }
   } else if (from.name === undefined) {
      lastRouteInfo.value = {
         name: 'home',
         params: from.params
      }
   }

   if (title) {
      document.title = to.params.locale === 'ru' ? (title as string) : (titleEN as string)
   } else {
      document.title = 'Datalouna'
   }

   let metaInfo: string

   if (description && descriptionEN) {
      metaInfo = to.params.locale === 'ru' ? (description as string) : (descriptionEN as string)
   } else {
      metaInfo = 'Datalouna'
   }

   let meta = document.querySelector('meta[name="description"]')

   if (meta) {
      meta.setAttribute('content', metaInfo)
   } else {
      meta = document.createElement('meta')
      meta.setAttribute('name', 'description')
      meta.setAttribute('content', metaInfo)
   }

   document.head.append(meta)

   const middlewareInfo = routeMiddleware(to, from)

   if (middlewareInfo !== undefined) {
      return middlewareInfo
   }

   const component = await import(`@/layouts/${layout}.vue`)
   to.meta.layoutComponent = component.default
})

export default router

<template>
   <iframe
      ref="videoPlayer"
      height="519"
      :src="src"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
      class="absolute inset-0 w-full"
      loading="lazy"
   ></iframe>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'

defineProps<{
   src: string
}>()

const isStarted = ref(false)
const progress = ref(0)

const emit = defineEmits<{
   status: ['start' | 'pause' | 'resume' | 'complete']
   progress: [number]
}>()

const videoPlayer = ref<HTMLIFrameElement>()

onMounted(() => {
   const Stream = window.Stream
   if (videoPlayer.value && Stream) {
      const api = Stream(videoPlayer.value)

      api.addEventListener('play', () => {
         emit('status', isStarted.value ? 'resume' : 'start')

         if (!isStarted.value) {
            isStarted.value = true
         }
      })

      api.addEventListener('pause', () => {
         if ((api.currentTime / api.duration) * 100 >= 100) {
            isStarted.value = false
            return emit('status', 'complete')
         }

         emit('status', 'pause')
      })

      api.addEventListener('timeupdate', () => {
         const progress_ = (api.currentTime / api.duration) * 100

         if (progress_ >= 25 && progress.value < 25) {
            progress.value = 25
            emit('progress', 25)
         } else if (progress_ >= 50 && progress.value < 50) {
            progress.value = 50
            emit('progress', 50)
         } else if (progress_ >= 75 && progress.value < 75) {
            progress.value = 75
            emit('progress', 75)
         } else if (progress_ >= 95 && progress.value < 95) {
            progress.value = 95
            emit('progress', 95)
         }
      })
   }
})
</script>

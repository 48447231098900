<script setup lang="ts">
import { computed } from 'vue'
import { locale } from '@/i18n'
import SwiperBlockContent from '@/components/SwiperBlockContent.vue'
import Stream from '@/components/StreamCF/Stream.vue'
import { useGtm } from '@gtm-support/vue-gtm'

const gtm = useGtm()

defineProps<{
   column: number
}>()

const list = computed(() => {
   if (locale.value === 'ru') {
      return [
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/69fdc98db4422f53291224f83fa11b1f/iframe?poster=https%3A%2F%2Fcustomer-ksmejglgjfbn3ydn.cloudflarestream.com%2F69fdc98db4422f53291224f83fa11b1f%2Fthumbnails%2Fthumbnail.jpg%3Fheight%3D519',
            name: 'Иван',
            age: '18 лет',
            city: 'Саратов',
            profession: 'Студент',
            data: 'ivan_student'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/e079cf350c5731ed22872a02313f4b59/iframe?poster=https%3A%2F%2Fcustomer-ksmejglgjfbn3ydn.cloudflarestream.com%2Fe079cf350c5731ed22872a02313f4b59%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            name: 'Тигран',
            age: '19 лет',
            city: 'Самара',
            profession: 'SMM',
            data: 'tigran_smm'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/fc5826687e6d1156987fd18484da91a2/iframe?poster=https%3A%2F%2Fcustomer-ksmejglgjfbn3ydn.cloudflarestream.com%2Ffc5826687e6d1156987fd18484da91a2%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            name: 'Полина',
            age: '28 лет',
            city: 'Москва',
            profession: 'SMM',
            data: 'polina_smm'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/fd3d34989e32b4d4b35a3f416bf6c7c5/iframe?poster=https%3A%2F%2Fcustomer-ksmejglgjfbn3ydn.cloudflarestream.com%2Ffd3d34989e32b4d4b35a3f416bf6c7c5%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            name: 'Тимур',
            age: '35 лет',
            city: 'Екатеринбург',
            profession: 'Инженер',
            data: 'timur_engineer'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/8a8a1f0f8caf1bf357743314cb27fe51/iframe?poster=https%3A%2F%2Fcustomer-ksmejglgjfbn3ydn.cloudflarestream.com%2F8a8a1f0f8caf1bf357743314cb27fe51%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            name: 'Элина',
            age: '27 лет',
            city: 'Москва',
            profession: 'Бизнес-Ассистент',
            data: 'elina_assistant'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/20be696168fbd67b91e8385ad0f288f6/iframe?poster=https%3A%2F%2Fcustomer-ksmejglgjfbn3ydn.cloudflarestream.com%2F20be696168fbd67b91e8385ad0f288f6%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            name: 'Сергей',
            age: '32 года',
            city: 'Санкт-Петербург',
            profession: 'Охранник',
            data: 'sergey_security'
         }
      ]
   } else {
      return [
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/f9eac52271a9a2c000f7a9b0539e660f/iframe?poster=https%3A%2F%2Fcustomer-ksmejglgjfbn3ydn.cloudflarestream.com%2Ff9eac52271a9a2c000f7a9b0539e660f%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            name: 'Levan',
            age: '28 years',
            city: 'Georgia',
            profession: 'Affiliate marketing',
            data: 'levan_affiliate'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/f5a612fecd7f9049c075781de1a232b1/iframe?poster=https%3A%2F%2Fcustomer-ksmejglgjfbn3ydn.cloudflarestream.com%2Ff5a612fecd7f9049c075781de1a232b1%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            name: 'Elina',
            age: '27 years',
            city: 'Moscow',
            profession: 'Business assistant',
            data: 'elina_assistant'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/89a98cce7950e71ad1c5d4a201943804/iframe?poster=https%3A%2F%2Fcustomer-ksmejglgjfbn3ydn.cloudflarestream.com%2F89a98cce7950e71ad1c5d4a201943804%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            name: 'Tigran',
            age: '19 years',
            city: 'Samara',
            profession: 'SMM',
            data: 'tigran_smm'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/4a2f801e167a351322d0f006e7bc77fe/iframe?poster=https%3A%2F%2Fcustomer-ksmejglgjfbn3ydn.cloudflarestream.com%2F4a2f801e167a351322d0f006e7bc77fe%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            name: 'Ivan',
            age: '18 years',
            city: 'Saratov',
            profession: 'Student',
            data: 'ivan_student'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/e9da891e8daf876c46c8ed9a6bd76463/iframe?poster=https%3A%2F%2Fcustomer-ksmejglgjfbn3ydn.cloudflarestream.com%2Fe9da891e8daf876c46c8ed9a6bd76463%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            name: 'Polina',
            age: '28 years',
            city: 'Moscow',
            profession: 'SMM',
            data: 'polina_smm'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/cdbf326cd7ed2231f7fdc48abd7485f0/iframe?poster=https%3A%2F%2Fcustomer-ksmejglgjfbn3ydn.cloudflarestream.com%2Fcdbf326cd7ed2231f7fdc48abd7485f0%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            name: 'Sergey',
            age: '32 years',
            city: 'Saint Petersburg',
            profession: 'Security',
            data: 'sergey_security'
         }
      ]
   }
})

const onStatus = (status: 'start' | 'pause' | 'resume' | 'complete', data: string) => {
   gtm?.trackEvent({
      event: 'video',
      type: status,
      lang: locale.value,
      name: data
   })
}

const onProgress = (progress: number, data: string) => {
   gtm?.trackEvent({
      event: 'video',
      type: `view_${progress}`,
      lang: locale.value,
      name: data
   })
}
</script>

<template>
   <SwiperBlockContent :column="column" :title="$t('about.testimonials.title')" :total="6">
      <div
         v-for="(review, index) in list"
         :key="index"
         class="flex-shrink-0 <laptop:w-292px! h-519px flex-shrink-0 rounded-10px overflow-hidden relative flex flex-col justify-end p-4px <laptop:snap-start"
         :style="`width: calc(${100 / column}% - 18px)`"
      >
         <Stream :src="review.link" @status="onStatus($event, review.data)" @progress="onProgress($event, review.data)" />

         <div class="h-54px rounded-10px bg-black-850/20 shadow-black flex flex-col items-center justify-center relative z-1">
            <div class="pb-8px s-medium">{{ review.name }}</div>
            <div class="xs-medium">{{ review.age }} • {{ review.city }} • {{ review.profession }}</div>

            <div class="absolute inset-0 bg-gray-50/4 border-2 border-gray-50/2 backdrop-blur-25 rounded-10px -z-1">
               <div class="bg-black-850/20 w-full h-full rounded-inherit relative"></div>
            </div>
         </div>
      </div>
   </SwiperBlockContent>
</template>

<style scoped></style>

import { ref, computed } from 'vue'
import dayjs from 'dayjs'
import type { Router } from 'vue-router'
import { i18nRoute } from '@/i18n'
import { useUserStore } from '@/stores'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { currentEvent, updateOnboardingSubscription } from '@/gql/requests'
import type { RouteParamsRaw } from 'vue-router'
import { sendEvent } from '@/composables/gtm'

export const lastRouteInfo = ref<{ name: string; params: RouteParamsRaw }>()

type TierName = string
export const getTierLogo = (tier: TierName) => {
   switch (tier) {
      case '1': {
         return '/img/tier/t1.svg'
      }
      case '2': {
         return '/img/tier/t2.svg'
      }
      case '3': {
         return '/img/tier/t3.svg'
      }
      case '4': {
         return '/img/tier/t4.svg'
      }
      case '5': {
         return '/img/tier/t5.svg'
      }
      default: {
         break
      }
   }
}

type TierData = {
   img: string
   teamTextKey: string
   eventTextKey: string
}

type TierInfoType = {
   [key: number]: TierData
}
export const tierInfo: TierInfoType = {
   0: {
      img: '/img/tier/t0.svg',
      teamTextKey: 'tiers.team0',
      eventTextKey: 'tiers.event0'
   },
   1: {
      img: '/img/tier/t1.svg',
      teamTextKey: 'tiers.team1',
      eventTextKey: 'tiers.event1'
   },
   2: {
      img: '/img/tier/t2.svg',
      teamTextKey: 'tiers.team2',
      eventTextKey: 'tiers.event2'
   },
   3: {
      img: '/img/tier/t3.svg',
      teamTextKey: 'tiers.team3',
      eventTextKey: 'tiers.event3'
   },
   4: {
      img: '/img/tier/t4.svg',
      teamTextKey: 'tiers.team4',
      eventTextKey: 'tiers.event4'
   },
   5: {
      img: '/img/tier/t5.svg',
      teamTextKey: 'tiers.team5',
      eventTextKey: 'tiers.event5'
   }
}

export const copyIsActive = ref(false)

export const copy = (url: string) => {
   navigator.clipboard.writeText(url)
   copyIsActive.value = true
   setTimeout(() => {
      copyIsActive.value = false
   }, 400)
}

export const isEventRunning = (startDate: Date, endDate?: Date) => {
   const now = dayjs()

   const start = dayjs(startDate)
   const end = endDate ? dayjs(endDate) : undefined

   if (start.isBefore(now) && (!end || end?.isAfter(now))) {
      return true
   }

   return false
}

export const useCurrentEvent = () => {
   const { result } = useQuery(currentEvent)
   const event = computed(() => result.value?.currentEvent)
   const eventIsLive = computed(() => {
      if (result.value?.currentEvent) {
         return isEventRunning(result.value.currentEvent.dateStart, result.value.currentEvent.dateEnd)
      }
      return false
   })

   return {
      event,
      eventIsLive
   }
}

export const roundToTwoDecimalPlaces = (number: number) => {
   return Math.round(number * 100) / 100
}

export const setTitleAndDescriptionHandler = (title: string, description: string) => {
   document.title = title

   let meta = document.querySelector('meta[name="description"]')

   if (!meta) {
      meta = document.createElement('meta')
      meta.setAttribute('name', 'description')
   }

   meta.setAttribute('content', description)
   document.head.append(meta)
}
export const setTitleAndDescription = (locale: string, titleRU: string, descriptionRU: string, titleEN: string, descriptionEN: string) => {
   if (locale === 'ru') {
      setTitleAndDescriptionHandler(titleRU, descriptionRU)
   } else {
      setTitleAndDescriptionHandler(titleEN, descriptionEN)
   }
}

export const changeOnboardingCollapseStatusRequest = () => {
   const { mutate: mutateSub } = useMutation(updateOnboardingSubscription)

   const set = (status: boolean) => {
      mutateSub({
         collapsed: status
      }).catch((error) => {
         console.log(error)
      })
   }

   return {
      set
   }
}

export function gainAccess(router: Router, isSendEvent = false, text?: string) {
   const userStore = useUserStore()

   if (userStore.userUnReg) {
      const _route = i18nRoute({ name: 'signout', query: { next: 'sub' } })
      if (isSendEvent && text) {
         sendEvent('cta', text, undefined, undefined, router, _route)
      }
      return router.push(_route)
   }

   if (userStore.userUnSub) {
      const _route = i18nRoute({ name: 'subscription' })
      if (isSendEvent && text) {
         sendEvent('cta', text, undefined, undefined, router, _route)
      }
      return router.push(_route)
   }
}

export function goToLink(link: string, blank = true) {
   const newWindow = window.open(link, blank ? '_blank' : '_self')
   if (!newWindow) {
      window.location.href = link
   }
}

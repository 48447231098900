<script setup lang="ts">
import { ref } from 'vue'
import { useCurrentEvent } from '@/composables'

defineProps<{
   small: boolean
}>()

const { event, eventIsLive } = useCurrentEvent()
const infoIsActive = ref(false)
</script>

<template>
   <article
      class="h-155px <tablet:h-225px bg-black-850 rounded-10px relative z-1 overflow-hidden px-24px <tablet:p-12px flex items-center <tablet:items-start bg-no-match <tablet:shadow-black"
      :class="{ 'bg-no-match-mask': !infoIsActive }"
   >
      <div class="peer relative flex flex-col gap-y-8px relative z-1" :class="infoIsActive ? '<tablet:hidden' : ''">
         <h3>{{ $t('dashboard.upcomingMatches.title') }}</h3>
         <div class="text-yellow-700 flex items-center gap-x-6px" @click="infoIsActive = true">
            <span class="i-custom-info-outline text-16px <tablethd:text-12px <tablet:text-16px"></span>
            <span class="m-semibold <tablethd:xs-medium <tablet:m-semibold">{{
               eventIsLive ? $t('ce2cWMFXUjnq4ToaDRSgP') : $t('Kls6SCLJoPsNUTkodALEb')
            }}</span>
         </div>
      </div>
      <div class="bg-hover peer" :class="infoIsActive ? 'bg-hover-active' : '-translate-x-full'">
         <div class="max-w-332px <tablet:max-w-220px m-medium flex items-center gap-x-22px <tablet:gap-x-10px">
            <div class="w-2px h-40px bg-yellow-700 rounded-full"></div>
            <span v-if="eventIsLive">{{ $t('YVdwVKJmFJUIcnK129h8E') }}</span>
            <span v-else>{{ $t('C0wGEpgN2eKwSSQYOCE9K') }}</span>
         </div>

         <button
            class="hidden <tablet:flex items-center gap-x-8px text-yellow-700 absolute top-12px left-12px"
            @click="infoIsActive = false"
         >
            <span class="i-custom-long-arrow-outline text-24px rotate-180"></span>
            <span class="s-semibold">{{ $t('BACK') }}</span>
         </button>
      </div>
      <div
         class="p-12px absolute top-0 left-288px <fullhd:left-264px <laptop:left-1/2 <laptop:-translate-x-1/2 <tablet:translate-x-0 <tablet:left-0 bottom-0 w-664px h-155px <tablet:w-full <tablet:h-full flex flex-col items-center <tablet:items-end <tablet:justify-end <tablet:gap-y-16px transition-transform duration-500 ease-in-out"
         :class="[
            infoIsActive ? '<tablet:p-36px' : '',
            small ? '&peer-hover:translate-x-30px <fullhd:&peer-hover:translate-x-100px' : '&peer-hover:translate-x-192px'
         ]"
      >
         <div class="tablet:flex-auto tablet:max-h-68px">
            <img v-if="event?.logo" :src="event?.logo" alt="event logo" class="w-44px h-44px <tablet:w-36px <tablet:h-36px" />
            <div v-else class="relative">
               <i class="i-custom-tournament-outline text-56px block text-black-800"></i>
               <div class="absolute inset-0 flex justify-center text-28px mt-14px">?</div>
            </div>
         </div>
         <div
            class="flex flex-col items-center gap-y-8px <tablet:items-end max-w-600px text-center <tablet:max-w-[47%] <tablet:text-right"
            :class="infoIsActive ? '<tablet:hidden' : ''"
         >
            <span class="text-yellow-700 m-semibold <tablet:s-semibold"
               >{{ eventIsLive ? $t('3FnrvZ-DtZ8uCajXVmSqA') : $t('hFb_8Cc4IYLrHSFfq5NR-') }} {{ $t('87Gel-reXkK3Y887ew3q0') }}</span
            >
            <h3 class="<tablet:l-medium line-clamp-2 <tablet:line-clamp-3">{{ event?.name || $t('lbfrIfSjwW-lSxK7EAn5C') }}</h3>
         </div>

         <img
            src="/img/banners/content-no-match.png"
            srcset="/img/banners/content-no-match@2.png 2x"
            alt="content-no-match"
            class="absolute inset-0 -z-1 pointer-events-none <tablet:hidden"
         />
      </div>

      <div class="hidden <tablet:flex absolute inset-0 -z-1" :class="{ 'bg-mobile bg-black-900': !infoIsActive }"></div>
   </article>
</template>

<style scoped>
.bg-mobile {
   clip-path: polygon(257px 0, 100% 0, 100% 100.1%, 129px 100.1%);
}

.bg-mobile::before {
   content: '';
   background-image: radial-gradient(41.47% 43.75% at 49.84% 100%, rgba(77, 80, 95, 0.2) 0%, rgba(29, 30, 36, 0) 100%);
   --uno: 'absolute bottom-0 -right-180px w-400px h-155px';
}
.bg-no-match {
   @apply bg-no-repeat bg-cover;
   background-image: url('/img/banners/bg-no-match.jpg');
}

.bg-hover {
   --uno: 'bg-black-850 w-443px h-full absolute top-0 left-0 flex items-center z-1';
   --uno: '<tablet:w-full tablet:-translate-x-full';
}

@screen tablet {
   .bg-hover {
      --uno: 'transition-transform duration-500 ease-in-out';
      clip-path: polygon(0 0, 100% 0, 81% 100%, 0% 100%);
      --uno: 'hover:translate-x-0';
      --uno: 'peer-hover:translate-x-0';
   }
}

@screen lt-tablet {
   .bg-hover-active {
      clip-path: polygon(0px 0, 311px 0, 180px 100.1%, 0 100.1%);
   }
   .bg-no-match {
      background-image: url('/img/banners/bg-no-match-mobile.jpg');
   }
   .bg-no-match-mask::before {
      content: '';
      background-image: url('/img/banners/mask-no-match-mobile.png');
      @apply absolute inset-0 bg-no-repeat bg-contain;
   }

   @media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .bg-no-match-mask::before {
         background-image: url('/img/banners/mask-no-match-mobile@2.png');
      }
   }
}
</style>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { i18nRoute, type I18nRoute } from '@/i18n'
import { sendEvent } from '@/composables/gtm'

const router = useRouter()

const handler = (text: string, route: I18nRoute) => {
   sendEvent('cta', text, '5steps_risk', undefined, router, route)
}
</script>

<template>
   <div class="flex gap-x-60px pl-80px <tablethd:flex-col <tablethd:items-center <fullhd:px-36px <tablethd:px-4px">
      <div class="<tablethd:mt-88px <tablethd:mb-24px <tablethd:px-8px">
         <header class="relative flex items-center gap-x-16px mb-24px <tablethd:hidden">
            <div class="w-2px h-22px rounded-10px bg-yellow-700 shadow-yellow"></div>
            <h2>{{ $t('WwxciiFMlvBsjpm2U83sz') }}</h2>
         </header>
         <div class="l-medium tablethd:ml-18px <tablet:m-medium">
            {{ $t('zcMVKSXGii7ITniE8gri8') }}
            <ul class="m-semibold <tablet:s-medium2 mt-16px mb-24px <tablethd:my-20px flex flex-col gap-y-4px">
               <li class="flex items-center gap-x-12px">
                  <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                     <div class="drop-shadow-yellow"><i class="i-custom-replacement-outline text-20px text-yellow-700 block"></i></div>
                  </div>
                  {{ $t('Wx0gcLvJ2bWucbHcZzf2Y') }}
               </li>
               <li class="flex items-center gap-x-12px">
                  <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                     <div class="drop-shadow-yellow"><i class="i-custom-empty-outline text-20px text-yellow-700 block"></i></div>
                  </div>
                  {{ $t('4gcRJSSnNYhlkTW4YOvbm') }}
               </li>
               <li class="flex items-center gap-x-12px">
                  <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                     <div class="drop-shadow-yellow"><i class="i-custom-thinks-outline text-20px text-yellow-700 block"></i></div>
                  </div>
                  {{ $t('WOc88N4LVTEllVnXv38h3') }}
               </li>
            </ul>
            <div class="flex gap-x-8px">
               {{ $t('OnAr_ok2XzFd1KieK-o7t') }}
               <i class="i-custom-risk-solid text-16px text-red-700"></i>
            </div>
            <span class="text-yellow-700">{{ $t('oMGuQh67_so2Sboe2bcgD') }}</span> {{ $t('GVewm4MNrCKLkq2nSoHjg') }}
            <span class="text-yellow-700">{{ $t('ceXGDRDDPdatcmJ1DKcZf') }}</span>
         </div>
      </div>

      <div class="relative -z-1 <tablethd:order-first">
         <picture>
            <source
               media="(max-width: 767px)"
               srcset="/img/what-you-get/match-info-bg-mobile.png 1x, /img/what-you-get/match-info-bg-mobile@2.png 2x"
            />
            <img srcset="/img/what-you-get/match-info-bg@2.png 2x" src="/img/what-you-get/match-info-bg.png" alt="match-info-bg" />
         </picture>
         <div class="absolute top-24px <tablet:top-12px left-1/2 -translate-x-1/2 w-488px h-235px <tablet:w-328px <tablet:h-217px">
            <picture>
               <source
                  media="(max-width: 767px)"
                  srcset="/img/what-you-get/risk/match-mobile.png 1x, /img/what-you-get/risk/match-mobile@2.png 2x"
               />
               <img srcset="/img/what-you-get/risk/match@2.png 2x" src="/img/what-you-get/risk/match.png" alt="match" />
            </picture>

            <div class="absolute top-72px right-45px <tablet:top-64px <tablet:right-9px flex items-end gap-x-8px">
               <div class="l-medium my-3px <tablet:m-medium">{{ $t('JNy3iYugU_NRdFcKzml7h') }}</div>
               <div class="flex flex-col items-center gap-4px">
                  <div class="w-1px h-135px <tablet:h-113px bg-yellow-700 rounded-full"></div>
                  <div class="w-24px aspect-square rounded-full bg-gray-50/4 border border-gray-50/2 backdrop-blur-25 flexc relative">
                     <div class="w-8px aspect-square rounded-full bg-yellow-700"></div>
                     <div class="note-glow"></div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <router-link
         :to="i18nRoute({ name: 'signout', query: { next: 'sub' } })"
         class="block text-center w-304px py-16px btn-lava-700 tablet:hidden"
         @click="
            handler(
               $t('subscription.profileWidget.subscriptionWidget.button.state1', {}, { locale: 'en' }),
               i18nRoute({ name: 'signout', query: { next: 'sub' } })
            )
         "
      >
         {{ $t('subscription.profileWidget.subscriptionWidget.button.state1') }}
      </router-link>
   </div>
</template>

<style scoped>
.note-glow {
   position: absolute;
   inset: 0;
   background: radial-gradient(50% 20% at 50% 100%, rgba(241, 185, 10, 0.3) 0%, rgba(29, 30, 36, 0) 100%);
   border-radius: 100px;
}
</style>

import { graphql } from './generate'
import { type GetFutureAndLiveMatchesQuery, type GetBestMatchesQuery } from './generate/graphql'
export * from './generate/graphql'

export const subscribeMatches = graphql(`
   subscription subscribeMatches {
      matchUpdated {
         id
         date
         status
         team1 {
            id
            name
            logo
            logo_x1
            logo_x2
         }
         team2 {
            id
            name
            logo
            logo_x1
            logo_x2
         }
         score
         winnerId
         chance
         hasPredict
         isPromo
         recommended
         event {
            id
            logo
            name
         }
         bestOf
         replacement
         comment {
            all
            team1
            team2
            expect
         }
         odds {
            matchOutcome {
               team1
               team2
            }
         }
         maps {
            map
            score
            pickTeamId
            chance
            matchId
         }
         contentPermission {
            chance {
               available
            }
         }
         finalChance
      }
   }
`)

export type ResponseFutureAndLiveMatches = GetFutureAndLiveMatchesQuery['matches']
export const getFutureAndLiveMatches = graphql(`
   query getFutureAndLiveMatches {
      matches(status: [LIVE, UPCOMING], sortAsc: true) {
         id
         date
         status
         team1 {
            id
            name
            logo
            logo_x1
            logo_x2
         }
         team2 {
            id
            name
            logo
            logo_x1
            logo_x2
         }
         score
         winnerId
         chance
         hasPredict
         isPromo
         recommended
         event {
            id
            logo
            name
         }
         bestOf
         replacement
         comment {
            all
            team1
            team2
            expect
         }
         odds {
            matchOutcome {
               team1
               team2
            }
         }
         maps {
            map
            score
            pickTeamId
            chance
            matchId
         }
         contentPermission {
            chance {
               available
            }
         }
         finalChance
      }
   }
`)

export type ResponseBestMatches = GetBestMatchesQuery['bestMatches']
export const getBestMatches = graphql(`
   query getBestMatches {
      bestMatches(limit: 6) {
         id
         date
         score
         team1 {
            id
            name
            logo
            logo_x1
            logo_x2
         }
         team2 {
            id
            name
            logo
            logo_x1
            logo_x2
         }
         odds {
            matchOutcome {
               team1
               team2
            }
         }
         chance
         event {
            id
            name
            logo
         }
         recommended
         comment {
            all
            team1
            team2
            expect
         }
         winnerId
         bestOf
      }
   }
`)

export const login = graphql(`
   mutation Login($email: String!, $password: String!) {
      login(email: $email, password: $password) {
         id
         name
         email
         avatarUrl
         betDiaryUrl
         telegramProfile {
            avatarUrl
            inviteLink
         }
         telegramData {
            ru {
               channel {
                  avatar
               }
            }
            en {
               channel {
                  avatar
               }
            }
            all {
               group {
                  avatar
               }
               channel {
                  avatar
               }
            }
         }
         discordProfile {
            avatar
         }
         progressBar {
            planDaysPercentComplited
            month
            freeDays
            planStart
            planEnd
            isErrorPayment
         }
         subscription {
            active
            endDate
            trial
            trialUsed
            autoPayment
            plan
            lastPaymentPlan
            planType
         }
         transactions {
            amount
            currency
            createdAt
            status
            paymentSystem
            receiptUrl
         }
         cards {
            id
            last4
            expMonth
            expYear
         }
         onboarding {
            collapsed
            register {
               onboarding
               promoMatch
               history
               card
            }
            subscribtion {
               onboarding
               looners
               education
               betDiary
            }
         }
         usedFreeDays {
            telegramGroup
            telegramChannel
            discord
            telegramFreeChannel
         }
      }
   }
`)

export const logout = graphql(`
   mutation Logout {
      logout
   }
`)

export const sendCodeToEmail = graphql(`
   mutation sendCodeToEmail($email: String!, $unique: Boolean, $exists: Boolean, $locale: UserLang) {
      sendVerificationEmail(email: $email, unique: $unique, exists: $exists, locale: $locale) {
         success
      }
   }
`)

export const registration = graphql(`
   mutation registration($email: String!, $password: String!, $code: String!, $locale: UserLang, $timezone: String) {
      register(email: $email, password: $password, code: $code, locale: $locale, timezone: $timezone) {
         id
         name
         email
         avatarUrl
         betDiaryUrl
         telegramProfile {
            avatarUrl
            inviteLink
         }
         telegramData {
            ru {
               channel {
                  avatar
               }
            }
            en {
               channel {
                  avatar
               }
            }
            all {
               group {
                  avatar
               }
               channel {
                  avatar
               }
            }
         }
         discordProfile {
            avatar
         }
         progressBar {
            planDaysPercentComplited
            month
            freeDays
            planStart
            planEnd
            isErrorPayment
         }
         subscription {
            active
            endDate
            trial
            trialUsed
            autoPayment
            plan
            lastPaymentPlan
            planType
         }
         transactions {
            amount
            currency
            createdAt
            status
            paymentSystem
            receiptUrl
         }
         cards {
            id
            last4
            expMonth
            expYear
         }
         onboarding {
            collapsed
            register {
               onboarding
               promoMatch
               history
               card
            }
            subscribtion {
               onboarding
               looners
               education
               betDiary
            }
         }
         usedFreeDays {
            telegramGroup
            telegramChannel
            discord
            telegramFreeChannel
         }
      }
   }
`)

export const recoverPassword = graphql(`
   mutation RecoverPassword($email: String!, $password: String!, $code: String!) {
      recoverPassword(email: $email, password: $password, code: $code) {
         success
      }
   }
`)

export const getSuccessTracker = graphql(`
   query getSuccessTracker {
      statistics {
         predicts {
            total
            recommended
         }
         recommended {
            last
            successful
            avgCoff
         }
         lastSixMonths {
            id
            month
            year
            avgLive
            raisedPrematch
            predictions
            winrate
         }
         looners {
            activeLooner
            rates
            bank
            topLooner
            link
         }
      }
   }
`)

export const getMatchesStats = graphql(`
   query GetMatchesStats($eventId: Int, $recommended: Boolean) {
      statistics(eventId: $eventId, recommended: $recommended) {
         accuracy {
            match {
               percent
               total
               correct
               avgCoff
            }
            map {
               percent
               total
               correct
               avgCoff
            }
         }
      }
   }
`)

export const getEducation = graphql(`
   query getEducation($locale: String) {
      education(locale: $locale) {
         locale
         cards {
            model
            items {
               title
               icon
               slug
               categorySlug
            }
         }
      }
   }
`)

export const getEducationCategoriesDatalouna = graphql(`
   query getEducationCategoriesDatalouna($locale: String) {
      help(locale: $locale) {
         locale
         categories {
            title
            slug
            items {
               title
               slug
               icon
               text
               categorySlug
            }
         }
      }
   }
`)

export const getEducationCategories = graphql(`
   query getEducationCategories($model: EducationModelType!, $locale: String) {
      articles(model: $model, locale: $locale) {
         locale
         categories {
            title
            items {
               icon
               title
               slug
               contentPermission
            }
         }
      }
   }
`)

export const getGuideCategories = graphql(`
   query getGuideCategories($locale: String) {
      guides(locale: $locale) {
         locale
         categories {
            title
            items {
               icon
               title
               slug
            }
         }
      }
   }
`)

export const getGuide = graphql(`
   query getGuide($slug: String!, $locale: String) {
      guide(slug: $slug, locale: $locale) {
         title
         updateDate
         hideFromSearch
         summary
         faq {
            question
            answer
         }
         icon
         closed
         text
         seoTitle
         enableCoverImage
         createdAt
         updatedAt
         publishedAt
         locale
         slug
         publishDate
      }
   }
`)

export const getEducationArticle = graphql(`
   query getEducationArticle($slug: String!, $model: EducationModelType!, $locale: String) {
      article(slug: $slug, model: $model, locale: $locale) {
         title
         slug
         icon
         text
         seoTitle
         publishedAt
         updatedAt
         lead
         readOut {
            title
            slug
            icon
            contentPermission
         }
         faq {
            question
            answer
         }
         mainAuthor {
            title
            author {
               name
               workTitle
               picture
               bio
               links {
                  url
                  type
               }
            }
         }
         secondAuthor {
            title
            author {
               picture
               name
               workTitle
            }
         }
      }
   }
`)

export const getEducationArticleDatalouna = graphql(`
   query getEducationArticleDatalouna($slug: String!, $locale: String) {
      helpCategory(slug: $slug, locale: $locale) {
         title
         slug
         icon
         updatedAt
         items {
            title
            slug
            icon
            text
            categorySlug
         }
      }
   }
`)

export const getEvents = graphql(`
   query getEvents($limit: Int, $skip: Int) {
      events(limit: $limit, skip: $skip) {
         id
         name
         banner
         format
         slug
         teams {
            id
            logo
            logo_x1
            logo_x2
         }
         dateStart
         dateEnd
         tier
         country
         city
         countryCode
         eventAccuracy {
            total {
               total
               percent
            }
            recommended {
               total
               percent
            }
            risk {
               total
               percent
            }
         }
      }
   }
`)

export const getEvent = graphql(`
   query getEvent($id: Float, $slug: String) {
      event(id: $id, slug: $slug) {
         id
         name
         banner
      }
   }
`)

export const getEventsSchedule = graphql(`
   query getEventsSchedule($limit: Int, $skip: Int, $hideEnded: Boolean) {
      events(limit: $limit, skip: $skip, hideEnded: $hideEnded, sort: true) {
         id
         name
         logo
         dateStart
         dateEnd
         tier
         countryCode
         country
         format
         prize
         slug
      }
   }
`)

export const currentEvent = graphql(`
   query currentEvent {
      currentEvent {
         id
         name
         logo
         dateStart
         dateEnd
         hasMatches
      }
   }
`)

export const search = graphql(`
   query search($model: EducationModelType, $query: String!) {
      search(model: $model, query: $query) {
         title
         lead
         icon
         slug
         categorySlug
         model
      }
   }
`)

export const getHistory = graphql(`
   query getHistory($limit: Int, $skip: Int, $recommended: Boolean, $eventId: Int) {
      matches(limit: $limit, skip: $skip, recommended: $recommended, status: ENDED, eventId: $eventId) {
         id
         team1 {
            id
            name
            logo
            logo_x1
            logo_x2
         }
         team2 {
            id
            name
            logo
            logo_x1
            logo_x2
         }
         score
         winnerId
         chance
         odds {
            matchOutcome {
               team1
               team2
            }
         }
         recommended
         comment {
            all
            team1
            team2
            expect
         }
         event {
            id
            name
            logo
         }
         date
         bestOf
      }
   }
`)

export const getMatch = graphql(`
   query GetMatch($id: Float!, $locale: String!) {
      match(id: $id) {
         id
         playerOfTheMatchId
         playerOfTheMatchStats {
            adr
            kpr
            kast
            rating
            dpr
            impact
         }
         score
         odds {
            matchOutcome {
               team1
               team2
            }
         }
         team1 {
            id
            name
            logo
            logo_x1
            logo_x2
            countryCode
            rank
            tier
            stats {
               maps {
                  name
                  strength
                  reliability
                  stability
                  form
                  formGraph {
                     week
                     value
                  }
               }
            }
         }
         team2 {
            id
            name
            logo
            logo_x1
            logo_x2
            countryCode
            rank
            tier
            stats {
               maps {
                  name
                  strength
                  reliability
                  stability
                  form
                  formGraph {
                     week
                     value
                  }
               }
            }
         }
         comment(locale: $locale) {
            all
            team1
            team2
            expect
         }
         replacement
         status
         isPromo
         recommended
         hasPredict
         chance
         finalChance
         date
         winnerId
         players {
            id
            nickname
            realName
            countryCode
            photo
            age
            country
            teamId
            stats {
               adr
               kpr
               kast
               rating
               dpr
               impact
            }
         }
         statsMaps {
            team1 {
               playerId
               playerName
               playerCountryCode
               adr
               diff
               kast
               death
               kills
               rating
            }
            team2 {
               playerId
               playerName
               playerCountryCode
               adr
               diff
               kast
               death
               kills
               rating
            }
         }
         maps {
            matchId
            map
            score
            pickTeamId
            chance
            scoreFirst
            scoreSecond
            scoreOvertime
            ctStart
            hasPredict
            stats {
               team1 {
                  playerId
                  playerName
                  playerCountryCode
                  adr
                  diff
                  kast
                  death
                  kills
                  rating
               }
               team2 {
                  playerId
                  playerName
                  playerCountryCode
                  adr
                  diff
                  kast
                  death
                  kills
                  rating
               }
            }
            roundHistory {
               team1 {
                  first
                  second
                  overtime
               }
               team2 {
                  first
                  second
                  overtime
               }
            }
         }
         event {
            id
            name
            logo
            format
         }
         bestOf
         history {
            team1 {
               id
               team1 {
                  id
                  logo
                  logo_x1
                  logo_x2
                  name
                  tierH: tier
               }
               team2 {
                  id
                  logo
                  logo_x1
                  logo_x2
                  name
                  tierH: tier
               }
               date
               score
               maps {
                  map
                  score
               }
            }
            team2 {
               id
               team1 {
                  id
                  logo
                  logo_x1
                  logo_x2
                  name
                  tierH: tier
               }
               team2 {
                  id
                  logo
                  logo_x1
                  logo_x2
                  name
                  tierH: tier
               }
               date
               score
               maps {
                  map
                  score
               }
            }
         }
         oddsGraph {
            date
            odds {
               matchOutcome {
                  team1
                  team2
               }
            }
         }
         mapPool {
            name
            chance
            orient
            hasPredict
         }
         streams {
            login
            name
            viewer
            img
            language
            link
         }
      }
   }
`)

export const getPaymentTypes = graphql(`
   query getPaymentTypes {
      GMPaymentTypes {
         type
         isActive
         currency
      }
   }
`)

/* -----USER------ */

export const getUser = graphql(`
   query getUser {
      user {
         id
         name
         email
         avatarUrl
         betDiaryUrl
         telegramProfile {
            avatarUrl
            inviteLink
         }
         telegramData {
            ru {
               channel {
                  avatar
               }
            }
            en {
               channel {
                  avatar
               }
            }
            all {
               group {
                  avatar
               }
               channel {
                  avatar
               }
            }
         }
         discordProfile {
            avatar
         }
         progressBar {
            planDaysPercentComplited
            month
            freeDays
            planStart
            planEnd
            isErrorPayment
         }
         subscription {
            active
            endDate
            trial
            trialUsed
            autoPayment
            plan
            lastPaymentPlan
            planType
         }
         transactions {
            amount
            currency
            createdAt
            status
            paymentSystem
            receiptUrl
         }
         cards {
            id
            last4
            expMonth
            expYear
         }
         onboarding {
            collapsed
            register {
               onboarding
               promoMatch
               history
               card
            }
            subscribtion {
               onboarding
               looners
               education
               betDiary
            }
         }
         usedFreeDays {
            telegramGroup
            telegramChannel
            discord
            telegramFreeChannel
         }
      }
   }
`)
export const updateUser = graphql(`
   mutation UpdateUser($name: String) {
      editUser(input: { name: $name }) {
         id
         name
         email
      }
   }
`)
export const updateDiary = graphql(`
   mutation UpdateDiary($betDiaryUrl: String) {
      editUser(input: { betDiaryUrl: $betDiaryUrl }) {
         id
         betDiaryUrl
      }
   }
`)
export const subscribeUser = graphql(`
   subscription subscribeUser {
      userUpdated {
         id
         name
         email
         avatarUrl
         betDiaryUrl
         telegramProfile {
            avatarUrl
            inviteLink
         }
         telegramData {
            ru {
               channel {
                  avatar
               }
            }
            en {
               channel {
                  avatar
               }
            }
            all {
               group {
                  avatar
               }
               channel {
                  avatar
               }
            }
         }
         discordProfile {
            avatar
         }
         progressBar {
            planDaysPercentComplited
            month
            freeDays
            planStart
            planEnd
            isErrorPayment
         }
         subscription {
            active
            endDate
            trial
            trialUsed
            autoPayment
            plan
            lastPaymentPlan
            planType
         }
         transactions {
            amount
            currency
            createdAt
            status
            paymentSystem
            receiptUrl
         }
         cards {
            id
            last4
            expMonth
            expYear
         }
         onboarding {
            collapsed
            register {
               onboarding
               promoMatch
               history
               card
            }
            subscribtion {
               onboarding
               looners
               education
               betDiary
            }
         }
         usedFreeDays {
            telegramGroup
            telegramChannel
            discord
            telegramFreeChannel
         }
      }
   }
`)
export const updatePassword = graphql(`
   mutation UpdatePassword($password: String!, $code: String!) {
      updateUserPassword(password: $password, code: $code) {
         id
      }
   }
`)

export const updateOnboardingSubscription = graphql(`
   mutation UpdateOnboardingSubscription(
      $subscribtion: OnboardingSubscribtionInputModel
      $register: OnboardingRegisterInputModel
      $collapsed: Boolean
   ) {
      editUser(input: { onboarding: { subscribtion: $subscribtion, register: $register, collapsed: $collapsed } }) {
         id
         onboarding {
            collapsed
            subscribtion {
               onboarding
               looners
               education
               betDiary
            }
            register {
               onboarding
               promoMatch
               history
               card
            }
         }
      }
   }
`)

export const sendFeedback = graphql(`
   mutation SendFeedback($email: String!, $message: String!) {
      sendFeedback(email: $email, message: $message) {
         success
      }
   }
`)

export const getUserTransactions = graphql(`
   query GetUserTransactions {
      user {
         transactions {
            id
            amount
            currency
            createdAt
            paymentSystem
            status
            receiptUrl
            card {
               id
               brand
               last4
               expMonth
               expYear
               isDefault
               userId
            }
            userId
         }
      }
   }
`)

export const updateUserLocale = graphql(`
   mutation UpdateUserLocale($locale: UserLang) {
      editUser(locale: $locale) {
         id
      }
   }
`)

/* -----USER END------ */

/* -----PAYMENT------ */
export const addCard = graphql(`
   mutation addCard($method: Method!) {
      addCard(method: $method) {
         id
         data
      }
   }
`)
export const deleteCard = graphql(`
   mutation DeleteCard($cardId: Float!) {
      deleteCard(cardId: $cardId) {
         id
      }
   }
`)
export const payRecurrent = graphql(`
   mutation PayRecurrent($planId: Int!, $method: Method!, $promocode: String) {
      createSubscriptionPayment(planId: $planId, method: $method, promocode: $promocode) {
         data
      }
   }
`)
export const createSubscriptionPayment = graphql(`
   mutation CreateSubscriptionPayment($planId: Int!, $method: Method!, $currency: String, $promocode: String) {
      createSubscriptionPayment(planId: $planId, method: $method, currency: $currency, promocode: $promocode) {
         id
         data
      }
   }
`)
/* -----PAYMENT END------ */

export const changeAutoPayment = graphql(`
   mutation ChangeAutoPayment($autoPayment: Boolean!) {
      editUser(input: { autoPayment: $autoPayment }) {
         id
      }
   }
`)

/* -----GM------ */
export const getGMPaymentLink = graphql(`
   mutation GetGMPaymentLink($planId: Int!, $returnUrl: String!, $type: String!, $currency: String, $promocode: String) {
      makeGMPayment(planId: $planId, returnUrl: $returnUrl, type: $type, currency: $currency, promocode: $promocode) {
         id
         data
      }
   }
`)
/* -----GM END------ */

export const sendLinkDiscord = graphql(`
   mutation SendLinkDiscord($code: String!) {
      linkToDiscord(code: $code) {
         success
      }
   }
`)

export const getPredictionPopup = graphql(`
   mutation GetPredictionPopup($email: String!, $locale: UserLang, $timezone: String!) {
      popup(email: $email, locale: $locale, timezone: $timezone) {
         success
      }
   }
`)

export const notify = graphql(`
   subscription Notify {
      notify {
         type
         category
         status
         matchId
         team1 {
            id
            name
            logo
         }
         team2 {
            id
            name
            logo
         }
         dateStart
         winner {
            id
            score
         }
         turn {
            id
         }
         pick
      }
   }
`)

export const checkPromoCode = graphql(`
   query CheckPromoCode($promocode: String!) {
      checkUsedPromocode(promocode: $promocode) {
         notExistsOrExpired
         used
         promocode {
            id
            name
            start
            end
            is_percent
            amount
            user_id
            is_active
            reccurent_payment
            used_count
            for_plans
         }
      }
   }
`)

export const getReadAlso = graphql(`
   query GetReadAlso($locale: String) {
      readAlso(locale: $locale) {
         locale
         items {
            title
            link
            imgLink
            panelId
         }
      }
   }
`)

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { i18nRoute, type I18nRoute } from '@/i18n'
import { sendEvent } from '@/composables/gtm'

const router = useRouter()

const handler = (text: string, route: I18nRoute) => {
   sendEvent('cta', text, '5steps_stat', undefined, router, route)
}
</script>

<template>
   <div
      class="flex <tablethd:flex-col <tablethd:items-center laptop:gap-x-138px <laptop:justify-between pl-100px <fullhd:px-36px <tablethd:px-4px <tablethd:mt-32px"
   >
      <div class="<tablethd:mt-36px <tablethd:mb-24px <tablethd:px-8px">
         <header class="relative flex items-center gap-x-16px mb-24px <tablethd:hidden">
            <div class="w-2px h-22px rounded-10px bg-yellow-700 shadow-yellow"></div>
            <h2>{{ $t('uqRUa-TQLaArZnyTGGiHY') }}</h2>
         </header>
         <div class="l-medium tablethd:ml-18px <tablet:m-medium">
            <div class="max-w-270px">
               {{ $t('tq5c8XhqIB7KS4KJ5AqbQ') }} <span class="text-yellow-700">{{ $t('z_74r1Iyrh-0er3aABp0e') }}</span>
               {{ $t('ShwCzd9KzU2JhY-L-0-P-') }}
            </div>
            <ul
               class="m-semibold <tablet:s-medium2 mt-32px mb-24px <tablethd:my-20px grid grid-rows-[1fr_1fr] tablethd:grid-flow-col gap-12px <tablethd:gap-4px"
            >
               <li class="flex items-center gap-x-12px">
                  <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                     <div class="drop-shadow-yellow"><i class="i-custom-brain-outline text-20px text-yellow-700 block"></i></div>
                  </div>
                  {{ $t('profile.availableBlock.title2') }}
               </li>
               <li class="flex items-center gap-x-12px">
                  <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                     <div class="drop-shadow-yellow"><i class="i-custom-rating-outline text-20px text-yellow-700 block"></i></div>
                  </div>
                  {{ $t('IDfs-hRsOr29Zh1uy7Xzn') }}
               </li>
               <li class="flex items-center gap-x-12px">
                  <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                     <div class="drop-shadow-yellow"><i class="i-custom-clock-outline text-20px text-yellow-700 block"></i></div>
                  </div>
                  {{ $t('breadcrumbs.history-matches') }}
               </li>
               <li class="flex items-center gap-x-12px">
                  <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                     <div class="drop-shadow-yellow"><i class="i-custom-twitch-outline text-20px text-yellow-700 block"></i></div>
                  </div>
                  {{ $t('3xMahrwC-Sbi8GFIq6bYS') }}
               </li>
            </ul>
         </div>
      </div>
      <div class="tablethd:mt-16px flex flex-col items-center <tablethd:contents">
         <div class="relative <tablethd:order-first w-480px h-183px <tablet:w-304px <tablet:h-184px">
            <picture>
               <source
                  media="(max-width: 767px)"
                  srcset="/img/what-you-get/stats/stats-mobile.png 1x, /img/what-you-get/stats/stats-mobile@2.png 2x"
               />
               <img srcset="/img/what-you-get/stats/stats@2.png 2x" src="/img/what-you-get/stats/stats.png" alt="stats" />
            </picture>
            <img
               src="/img/what-you-get/stats/icons-stats.png"
               srcset="/img/what-you-get/stats/icons-stats@2.png 2x"
               alt="stats icons"
               class="absolute -top-30px left-1/2 -translate-x-1/2"
            />
         </div>
         <div class="tablethd:-translate-y-18px">
            <router-link
               :to="i18nRoute({ name: 'signin' })"
               class="block text-center btn-lava-700 l-semibold w-400px <tablet:w-304px py-16px"
               @click="handler($t('6h-KU1ZoISmmb6HWq-GpZ', {}, { locale: 'en' }), i18nRoute({ name: 'signin' }))"
            >
               {{ $t('6h-KU1ZoISmmb6HWq-GpZ') }}
            </router-link>
         </div>
      </div>
   </div>
</template>

<style scoped></style>

import { useGtm } from '@gtm-support/vue-gtm'
import { type Router } from 'vue-router'
import type { I18nRoute } from '@/i18n'

const getFullRouteUrl = (router: Router, route: I18nRoute) => {
   const resolvedRoute = router.resolve(route)
   const path = resolvedRoute.href

   const baseUrl = window.location.origin

   return new URL(path, baseUrl).toString()
}

export function sendEvent(
   type: string,
   text: string,
   destination?: string,
   value?: string,
   router?: Router,
   routeI18n?: I18nRoute,
   link?: string
) {
   const gtm = useGtm()
   // eslint-disable-next-line unicorn/no-null
   let button_url: string | null = null

   if (router && routeI18n) {
      button_url = getFullRouteUrl(router, routeI18n)
   }

   if (link) {
      const baseUrl = window.location.origin
      button_url = new URL(link, baseUrl).toString()
   }

   gtm?.trackEvent({
      event: 'click_button',
      type,
      text,
      destination,
      value,
      button_url,
      page_url: window.location.href
   })
}

<script setup lang="ts">
import { computed } from 'vue'
import { locale } from '@/i18n'
import dayjs from 'dayjs'

const props = defineProps<{
   amount: number | ''
   predictions: number
   winrate: number
   odds: number
   year: number
   month: number
}>()

const visibleYear = computed(() => {
   return props.year !== new Date().getFullYear()
})

const profit = computed(() => {
   const win = Math.round((props.winrate / 100) * props.predictions)
   const lose = props.predictions - win

   return Math.round((props.amount || 0) * (props.odds - 1) * win - (props.amount || 0) * lose)
})
</script>

<template>
   <div class="h-130px rounded-10px bg-black-850 p-8px <tablethd:w-200px flex-shrink-0 <tablethd:snap-start">
      <div
         class="h-46px py-13px px-12px bg-gray-50/4 border border-gray-50/4 rounded-10px flex items-center justify-between relative z-1"
         :class="profit > 0 ? 'stat-month-bg' : ''"
      >
         <div class="s-semibold capitalize relative">
            {{
               dayjs()
                  .locale(locale)
                  .month(month - 1)
                  .format('MMMM')
            }}

            <div v-if="visibleYear" class="absolute left-0 top-[calc(100%+1px)] xs-medium opacity-50">{{ year }}</div>
         </div>
         <span class="m-semibold truncate" :class="profit > 0 ? 'text-green-500' : 'text-gray-50/50'">
            <template v-if="profit > 0">+</template>
            <template v-else-if="profit < 0">-</template>
            ${{ profit > 0 ? profit : profit * -1 }}
         </span>
      </div>
      <div class="mt-8px mb-4px py-8px px-12px flex items-center justify-between relative border-gradient">
         <div class="xs-medium flex items-center gap-x-6px">
            <i class="i-custom-crystalballmini-outline text-12px"></i>
            {{ $t('A9NpHMLHBXXuWZXYdXTdd') }}
         </div>
         <span class="s-medium">{{ predictions }}</span>
      </div>
      <div class="py-8px px-12px flex items-center justify-between relative border-gradient">
         <div class="xs-medium flex items-center gap-x-6px">
            <i class="i-custom-target-outline text-12px"></i>
            {{ $t('RablyduNKxMvL4_Vi-Qhk') }}
         </div>
         <span class="s-medium">{{ winrate }}%</span>
      </div>
   </div>
</template>

<style scoped>
.stat-month-bg::after {
   content: '';
   --uno: 'absolute inset-0 pointer-events-none';
   background: radial-gradient(14% 60% at 100% 50%, rgba(44, 194, 86, 0.1) 0%, rgba(29, 30, 36, 0) 100%);
}

.border-gradient::before {
   content: '';
   position: absolute;
   inset: 0;
   border-radius: 10px;
   padding: 1px;
   -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
   -webkit-mask-composite: xor;
   mask-composite: exclude;
   --uno: 'pointer-events-none';
   background-image: var(--gradient-plate-900);
}
</style>
